// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '~@angular/material' as mat;
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$mat-boiler-plate-primary: (
  50: #e5e7eb,
  100: #bec4cc,
  200: #939dab,
  300: #687589,
  400: #47586f,
  500: #273a56,
  600: #23344f,
  700: #1d2c45,
  800: #17253c,
  900: #0e182b,
  A100: #699bff,
  A200: #3679ff,
  A400: #0357ff,
  A700: #004ee9,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff
  )
);

// $Boiler-Plate-primary: mat.define-palette($mat-boiler-plate-primary);

$mat-boiler-plate-accent: (
  50: #fff7eb,
  100: #ffeccd,
  200: #ffe0ab,
  300: #ffd389,
  400: #ffc970,
  500: #ffc057,
  600: #ffba4f,
  700: #ffb246,
  800: #ffaa3c,
  900: #ff9c2c,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffecd8,
  A700: #ffdfbe,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);
// $Boiler-Plate-accent: mat.define-palette($mat-boiler-plate-accent);

$mat-boiler-plate-warn: (
  50: #fce4e8,
  100: #f7bbc5,
  200: #f18e9e,
  300: #eb6077,
  400: #e73e5a,
  500: #e31c3d,
  600: #e01937,
  700: #dc142f,
  800: #d81127,
  900: #d0091a,
  A100: #fffbfb,
  A200: #ffc8cb,
  A400: #ff959b,
  A700: #ff7b83,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);
// The warn palette is optional (defaults to red).
// $Boiler-Plate-warn: mat.define-palette($mat-boiler-plate-warn);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
// $Boiler-Plate-theme: mat.define-light-theme(
//   (
//     color: (
//       primary: $Boiler-Plate-primary,
//       accent: $Boiler-Plate-accent,
//       warn: $Boiler-Plate-warn
//     )
//   )
// );

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include mat.all-component-themes($Boiler-Plate-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  // css color variables
  // primary colors:
  // --base-color: #212121;
  // --midnight-blue: #101f36;
  // --dark-blue: #273a56;
  // --medium-blue: #25508e;
  // --blue: #2f64b2;
  // --cerulean-blue: #0072aa;
  // --oyster-bay: #d6ebeb;
  // //greys:
  // --darkest-grey: #6f7479;
  // --dark-grey: #8a8a8a;
  // --medium-grey: #d7d7d7;
  // --light-grey-1: #393939;
  // --light-grey-2: #f2f2f2;
  // --light-grey-3: #f3f3f3;
  // //secondary colors:
  // --green: #2e8540;
  // --medium-green: #4aa564;
  // --light-green: #eaf4e6;
  // --red: #e31c3d;
  // --medium-red: #e59393;
  // --light-red: #fceded;
  // --golden-yellow: #ffc057;
}

.fx-host {
  // to be used on routed components
  box-sizing: border-box;
  max-height: calc(100vh - 140px);
  min-height: calc(100vh - 140px);
  height: calc(100vh - 140px);
  display: flex;
}
.overflow-y-auto {
  overflow-y: auto;
}
.padding-left-right-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.padding-bottom-0 {
  padding-bottom: 0;
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
}

.margin-top-bottom-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.margin-left-05 {
  margin-left: 0.5rem;
}

.margin-bottom-15 {
  margin-bottom: 1.5rem;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.margin-top-0 {
  margin-top: 0 !important;
}

.padding-0 {
  padding: 0 !important;
}
.padding-05 {
  padding: 0.5rem;
}
// temp fix for advanced-pie-legend
.advanced-pie-legend .legend-items-container .legend-items {
  overflow: initial !important;
}
.global-search {
  .mat-input-element {
    color: #000;
  }
  .mat-icon {
    color: #000;
  }
  .mat-form-field-outline-start {
    background-color: #fff;
  }
  .mat-form-field-outline-gap {
    background-color: #fff;
  }
  .mat-form-field-outline-end {
    background-color: #fff;
  }
}

.advanced-search-container .mat-expansion-panel-body {
  padding: 0;
}
